import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import { Col, Container, Row } from 'reactstrap';
import platform from 'platform';
import TastyModal from 'components/TastyModal';
import downloadIcon from 'images/icon-arrow-down-hover.svg';
import TastyButton from 'components/TastyButton';
import { SUPPORT_EMAIL, SUPPORT_PHONE } from 'utils/constants';
import externalLinks from 'utils/externalLinks';

const DESKTOP_VERSION = '2.15.0';

const WIN32 = 'win32';
const WIN64 = 'win64';
const DEB = 'deb';
const RPM = 'rpm';
const MAC_INTEL = 'mac intel';
const MAC_APPLE_SILICON = 'mac apple';
const UNSUPPORTED = 'unsupported'

const toDownloadUrl = suffix => `https://download.tastytrade.com/desktop-2.x.x/${DESKTOP_VERSION}/tastytrade-${DESKTOP_VERSION}${suffix}`
const toDownloadData = (title, suffix) => ({title, link: toDownloadUrl(suffix)})

const DOWNLOADS = {
  [MAC_INTEL]: toDownloadData('Mac OS Intel', '.dmg'),
  [MAC_APPLE_SILICON]: toDownloadData('Mac OS Apple Silicon', '-aarch64.dmg'),
  [WIN32]: {
    error: `We're sorry, but Windows 32-bit is not supported for tastytrade Desktop. Please upgrade to Windows 64-bit or use tastytrade web-based trading. If we made an OS detection mistake, please choose the appropriate link from the list of All Installers`,
  },
  [WIN64]: toDownloadData('Windows 64-bit', '.msi'),
  [RPM]: toDownloadData('Linux 64-bit rpm', '-1.x86_64.rpm'),
  [DEB]: toDownloadData('Linux 64-bit deb', '-1_amd64.deb'),
  [UNSUPPORTED]: {
    error: `We're sorry. Either we don't support tastytrade Desktop for your current operating system or you're a Linux user. If the latter, please choose the appropriate link from the list of All Installers.`
  }
};

const DownloadTitle = styled.div`
  padding-left: 1.25rem;
  background-image: url('${downloadIcon}');
  background-position: 0 50%;
  background-size: 1.0625rem;
  background-repeat: no-repeat;
  font-family: ${prop('theme.fonts.condensed')};
`;

const Wrapper = styled(Container)`
  font-family: ${prop('theme.fonts.default')};
  font-size: 0.875rem;
  font-weight: 400;

  ${TastyButton} {
    font-size: 1.5em;
    display: block;
    margin-bottom: 1rem;
    width: auto;
  }
`;

const debTests = [
  /ubuntu/i,
  /debian/i,
];

const rpmTests = [
  /red\s*hat/i,
  /fedora/i,
  /centos/i,
  /rhel/i
];

function detectInstallerType() {
  const osName = platform.os.family.toLowerCase();
  if (osName.includes('mac') || osName.includes('os x')) {
    return MAC_INTEL;
  }

  if (osName.includes('win')) {
    return platform.os.architecture === 64 ? WIN64 : WIN32;
  }

  if (debTests.find(p => p.test(osName))) {
    return DEB;
  }

  if (rpmTests.find(p => p.test(osName))) {
    return RPM;
  }

  return UNSUPPORTED;
}

function DownloadInstallerLink(type) {
  const download = DOWNLOADS[type]
  return (
    <li>
      <a
        href={download.link}
        rel='noopener noreferrer'
        target='_blank'
      >
        {download.title}
      </a>
    </li>
  )
}

function DownloadDetectedInstallerButton(type) {
  const download = DOWNLOADS[type]
  if (download.error) {
    return (
      <p>{download.error}</p>
    )
  }

  return (
    <TastyButton
      href={download.link}
      target='_blank'
      primary
    >
      Download Installer
    </TastyButton>
  )
}

const propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
};

const defaultProps = {
  isOpen: false
};

function DownloadModal({ isOpen, toggle }) {
  const [detectedInstallerType, setDetectedInstallerType] = useState(UNSUPPORTED);

  useEffect(() => {
    setDetectedInstallerType(detectInstallerType())
  }, [platform.os.family]);

  return (
    <TastyModal
      title={<DownloadTitle>Desktop</DownloadTitle>}
      isOpen={isOpen}
      toggle={toggle}
      size='lg'
    >
      <Wrapper>
        <Row>
          <Col md={12} lg={6}>
            {DownloadDetectedInstallerButton(detectedInstallerType)}
            <p>
              Desktop requires an active tastytrade Account and will not work without it.{' '}
              <a
                href={externalLinks.openAnAccount}
                rel='noopener noreferrer'
                target='_blank'
              >
                Open an Account.
              </a>
            </p>
            <h5>All Installers</h5>
            <Row>
              <Col xs={6}>
                <ul className='list-unstyled'>
                  {DownloadInstallerLink(MAC_INTEL)}
                  {DownloadInstallerLink(MAC_APPLE_SILICON)}
                  {DownloadInstallerLink(WIN64)}
                </ul>
              </Col>
              <Col xs={6}>
                <ul className='list-unstyled'>
                  {DownloadInstallerLink(DEB)}
                  {DownloadInstallerLink(RPM)}
                </ul>
              </Col>
            </Row>
            <h5>Support</h5>
            <p>
              <a
                href={`mailto:${SUPPORT_EMAIL}`}
                rel='noopener noreferrer'
                target='_blank'
              >
                {SUPPORT_EMAIL}
              </a>
              <br />
              {SUPPORT_PHONE}
            </p>
          </Col>
          <Col md={12} lg={6}>
            <h5>Updates</h5>
            <p>Software will auto-update to the latest version after the installation is complete and whenever new versions are released. The installer includes the required Java Runtime Environment.</p>

            <h5>Requirements</h5>
            <strong><em>Operating System</em></strong>
            <ul className='list-unstyled'>
              <li>Mac OS / Windows 64-bit / Linux 64-bit</li>
              <li>RAM 2 GB minimum, 8 GB recommended</li>
              <li>Hard Disk Space: 200 MB for installation, 250 MB recommended</li>
            </ul>

            <strong><em>Network</em></strong>
            <ul className='list-unstyled'>
              <li>Connection Speed: 1.5Mbps minimum, 6Mbps recommended</li>
              <li>Software connects to our services over SSL</li>
              <li>HTTPS on ports 443 and ports 7301-7305 for market data.</li>
            </ul>
          </Col>
        </Row>
      </Wrapper>
    </TastyModal>
  );
}

DownloadModal.propTypes = propTypes;
DownloadModal.defaultProps = defaultProps;

export default DownloadModal;
