import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools'
import { Container, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReadyToTrade, { ReadyToTradeSection } from 'components/ReadyToTrade';
import externalLinks from 'utils/externalLinks';
import Layout from 'components/Layout';
import SectionsWrapper from 'components/SectionsWrapper';
import Hero from 'components/Hero';
import Section from 'components/Section';
import SmallPrint from 'components/SmallPrint';
import SectionDivider from 'components/SectionDivider';
import TastyButton from 'components/TastyButton';
import Headline from 'components/Headline';
import technologyHero from 'images/hero/image-technology-hero.jpg';
import curveAnalysisGif from 'images/curve-analysis-gif.gif';
import quickRollGif from 'images/quick-roll-gif.gif';
import quickOrderGif from 'images/quick-order-gif.gif';
import percentOfProfitGif from 'images/percent-of-profit-gif.gif';
import etfEquivalentGif from 'images/etf-equivalent-gif.gif';
import inAppTradingGif from 'images/in-app-trading-gif.gif';
import curveAnalysisStatic from 'images/curve-analysis-static.png';
import quickRollStatic from 'images/quick-roll-static.png';
import quickOrderStatic from 'images/quick-order-static.png';
import percentOfProfitStatic from 'images/percent-of-profit-static.png';
import etfEquivalentStatic from 'images/etf-equivalent-static.png';
import inAppTradingStatic from 'images/in-app-trading-static.png';
import desktop from 'images/image-desktop-tech.png';
import laptop from 'images/image-laptop-tech.png';
import mobile from 'images/image-mobile-tech.png';
import techTeam from 'images/tech-team.png';
import TeamSection from 'components/TeamSection';
import ImageHoverSwap from 'components/ImageHoverSwap';
import withLocation from 'hocs/withLocation';
import DownloadModal from 'components/DownloadModal';
import DesktopScreenshotModal from 'components/DesktopScreenshotModal';
import BrowserScreenshotModal from 'components/BrowserScreenshotModal';
import MobileScreenshotModal from 'components/MobileScreenshotModal';
import { useLayoutContext } from 'context/LayoutContext';
import TitleHeader from 'components/TitleHeader';
import SEO from 'components/SEO';

const TechnologyWrapper = styled(SectionsWrapper)`
  &&& {
    padding: 0;

    ${ReadyToTradeSection} {
      justify-content: center;
    }

    .container-fluid {
      text-align: left;

      .h5 {
        font-family: ${prop('theme.fonts.default')};
        font-weight: bold;
        font-size: 1em;
        text-transform: uppercase;
        text-align: center;
        margin: 2em 0 0.75em;
      }

      img.tech-image {
        height: 12.5rem;
      }
    }

    ${SmallPrint} {
      font-size: 0.9em;
      font-weight: 300;

      p {
        font-size: 1em;
        margin: 0;
        font-weight: 300;
      }
    }
  }
`;

const HeadlineSection = styled(Section)`
  text-align: center;
  padding-bottom: 5rem;

  ${Headline} {
    font-size: 3rem;

    @media (max-width: ${prop('theme.breakpoints.sm')}) {
      font-size: 1.8rem;
    }

    @media (max-width: ${prop('theme.breakpoints.xs')}) {
      font-size: 1.6rem;
    }
  }

  ${TastyButton} {
    margin-top: 1em;
  }

  img {
    max-width: 100%;
  }
`;

const PlatformSection = styled(HeadlineSection)`
  & > p {
    text-align: left;
    margin: 2em 0;
  }
`;

const AppLink = styled.button`
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 0.75em;
  display: block;
  margin: 0.5em 0;
  padding: 0;
  background: none;
  border: none;
`;

const DetailsSection = styled.section`
  &&&& {
    font-size: 0.74em;
    font-family: 'Open Sans';

    .h5 {
      text-align: left;
      margin-bottom: 0;
    }

    p {
      font-size: 1em;
    }

    ul {
      padding-left: 1.5em;
    }
  }
`;

const DownloadTasty = styled(TastyButton)`
  @media (max-width: ${prop('theme.breakpoints.xs')}) {
    width: 100%;
  }
`

const TechnologyMobileButton = styled(TastyButton)`
  @media (max-width: ${prop('theme.breakpoints.xs')}) {
    width: 100%;
  }
`

const TechnologyGifs = styled(ImageHoverSwap)`
  @media (max-width: ${prop('theme.breakpoints.xs')}) {
    display: none;
  }
`

const Footnotes = styled.div`
  margin-top: 2em;
  text-align: left;
  .h5 {
    color: ${prop('theme.colors.darkGray')};
    font-family: ${prop('theme.fonts.default')};
    font-size: 0.9em;
    font-style: normal;
    font-weight: bold;
  }
  p {
    margin-bottom: 2em;
  }
`;

const UnderlineText = styled.span`
  text-decoration: underline;
`;

function TechnologyPage({ showPromoBar, search }) {
  const { state: layoutContextState } = useLayoutContext();
  const { showAlert } = layoutContextState;
  const [showMoreDesktop, setShowMoreDesktop] = useState(false);
  const [showMoreBrowser, setShowMoreBrowser] = useState(false);
  const [showMoreMobile, setShowMoreMobile] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showDesktopScreenshotModal, setShowDesktopScreenshotModal] = useState(false);
  const [showBrowserScreenshotModal, setShowBrowserScreenshotModal] = useState(false);
  const [showMobileScreenshotModal, setShowMobileScreenshotModal] = useState(false);

  // Check if query param 'download=1' is set to auto-show download modal.
  useEffect(() => {
    if (search && search.download) {
      setShowDownloadModal(true);
    }
  }, []);

  const desktopIcon = showMoreDesktop ? 'caret-down' : 'caret-up';
  const browserIcon = showMoreBrowser ? 'caret-down' : 'caret-up';
  const mobileIcon = showMoreMobile ? 'caret-down' : 'caret-up';

  return (
    <Layout>
      <SEO
        title='Technology'
        description='The tastytrade software is available as downloadable software or as an internet application as well as a mobile app.'
        canonicalHref='https://tastytrade.com/technology'
      />
      <main>
        <Hero
          heroImage={technologyHero}
          $showAlert={showAlert}
          showPromoBar={showPromoBar}
        >
          <h1>Technology</h1>
          <h2>Freedom to trade fast</h2>
        </Hero>
        <TechnologyWrapper>
          <HeadlineSection withPadding>
            <Headline>
              See It
              <span> / </span>
              Click It
              <span> / </span>
              Trade It
            </Headline>
            <p>
              We built tastytrade to be one of the fastest, most reliable, and
              most secure trading platforms in the world. At tastytrade, you can
              invest your time as wisely as you do your money. With our See It,
              Click It, Trade It design, your trading becomes efficient,
              confident, and current. What’s not to love?
            </p>
            <TastyButton primary onClick={() => setShowDownloadModal(true)}>Download Tastytrade Now</TastyButton>
          </HeadlineSection>
          <Section withPadding>
            <Row>
              <Col md={12} lg={6} className='mt-5'>
                <TitleHeader>Curve Analysis</TitleHeader>
                <p>
                  The curve view gives you a visual way to intuitively set up trades
                  for entry. With a click & drag interface and clear profit and loss
                  zones, you can adjust your strikes and expirations quickly and
                  easily…while keeping an eye on your Probability of Profit!
                </p>
                <TechnologyMobileButton
                  href={externalLinks.curveAnalysis}
                  rel='noopener noreferrer'
                  target='_blank'
                  aria-label='Learn More About Curve Analysis'
                >
                  Learn More
                </TechnologyMobileButton>
              </Col>
              <Col md={12} lg={6}>
                <TechnologyGifs
                  src={curveAnalysisStatic}
                  hoverSrc={curveAnalysisGif}
                  alt='Curve Analysis'
                  className='w-100'
                />
              </Col>
            </Row>
          </Section>
          <Section withPadding>
            <SectionDivider />
            <Row>
              <Col md={12} lg={6}>
                <TechnologyGifs
                  src={quickRollStatic}
                  hoverSrc={quickRollGif}
                  alt='Quick Roll'
                  className='w-100'
                />
              </Col>
              <Col md={12} lg={6} className='mt-5'>
                <TitleHeader>Quick Roll</TitleHeader>
                <p>
                  The quick roll feature allows you to roll your option position
                  into the next expiration cycle with a simple right-click. You can
                  set your default rolls to the next weekly or monthly expiration
                  cycle for efficient trading that works for you.
                </p>
                <TechnologyMobileButton
                  href={externalLinks.quickRoll}
                  rel='noopener noreferrer'
                  target='_blank'
                  aria-label='Learn More About Quick Roll'
                >
                  Learn More
                </TechnologyMobileButton>
              </Col>
            </Row>
          </Section>
          <Section withPadding>
            <SectionDivider />
            <Row>
              <Col md={12} lg={6} className='mt-5'>
                <TitleHeader>Quick Order Adjustments</TitleHeader>
                <p>
                  Working orders can also be adjusted with a simple right-click. You
                  can cancel, replace, duplicate, or even invert working orders that
                  haven’t been filled yet. Once an order has been filled, you can
                  quickly double down on trades you like or close the ones you
                  don’t.
                </p>
              </Col>
              <Col md={12} lg={6}>
                <TechnologyGifs
                  src={quickOrderStatic}
                  hoverSrc={quickOrderGif}
                  alt='Quick Order Adjustments'
                  className='w-100'
                />
              </Col>
            </Row>
          </Section>
          <Section withPadding>
            <SectionDivider />
            <Row>
              <Col md={12} lg={6}>
                <TechnologyGifs
                  src={percentOfProfitStatic}
                  hoverSrc={percentOfProfitGif}
                  alt='Percent of Profit Limit Orders'
                  className='w-100'
                />
              </Col>
              <Col md={12} lg={6} className='mt-5'>
                <TitleHeader>Percent of Profit Limit Orders</TitleHeader>
                <p>
                  You can choose probabilities over prediction with{' '}
                  percent-of-profit limit orders. Instead of picking a random price
                  target for your position, you can choose a price that has better
                  odds of occurring.
                </p>
                <TechnologyMobileButton
                  href={externalLinks.percentOfProfitLimitOrders}
                  rel='noopener noreferrer'
                  target='_blank'
                  aria-label='Learn More About Percent of Profit Limit Orders'
                >
                  Learn More
                </TechnologyMobileButton>
              </Col>
            </Row>
          </Section>
          <Section withPadding>
            <SectionDivider />
            <Row>
              <Col md={12} lg={6} className='mt-5'>
                <TitleHeader>ETF-Equivalent Futures Delta</TitleHeader>
                <p>
                  Futures are complex, but understanding risk doesn&apos;t have to be. With varying tick-sizes and notional
                  values, finding true delta exposure can be tough without a benchmark. We have created an easy way for you
                  to see your ETF-future equivalent delta.
                </p>
                <TechnologyMobileButton
                  href={externalLinks.etfEquivalentFuturesDelta}
                  rel='noopener noreferrer'
                  target='_blank'
                  aria-label='Learn More About ETF-Equivalent Futures Delta'
                >
                  Learn More
                </TechnologyMobileButton>
              </Col>
              <Col md={12} lg={6}>
                <TechnologyGifs
                  src={etfEquivalentStatic}
                  hoverSrc={etfEquivalentGif}
                  alt='ETF Equivalent Futures Delta'
                  className='w-100'
                />
              </Col>
            </Row>
          </Section>
          <Section withPadding>
            <SectionDivider />
            <Row>
              <Col md={12} lg={6}>
                <TechnologyGifs
                  src={inAppTradingStatic}
                  hoverSrc={inAppTradingGif}
                  alt='In-App trading inspiration'
                  className='w-100'
                />
              </Col>
              <Col md={12} lg={6}>
                <TitleHeader>In-App Trading Inspiration</TitleHeader>
                <p>
                  Everyone goes through trader’s block. It happens. That’s why we pump fresh insight straight into the
                  tastytrade platform. We have a dedicated feed where you can see what really excites our select traders,
                  and we provide access to a live video stream where you can watch smart traders tackle the markets in real
                  time.
                </p>
                <TechnologyMobileButton
                  to='/education'
                  aria-label='Learn More About In-App Trading Inspiration'
                >
                  Learn More
                </TechnologyMobileButton>
              </Col>
            </Row>
          </Section>
          <PlatformSection withPadding>
            <SectionDivider />
            <TitleHeader className='mb-5'>
              A Platform to Fit the Way <UnderlineText>You</UnderlineText> Trade
            </TitleHeader>
            <Container fluid>
              <Row>
                <Col md={12} lg={4} className='mt-5'>
                  <img className='tech-image' src={desktop} alt='Desktop' />
                  <h3 className='h5'>Desktop</h3>
                  <p>
                    You’re at mission control with your fingers on the mouse, eyes
                    on the market, and brain on full blast. tastytrade on your
                    computer and a big screen make for full immersion in trading
                    power.
                  </p>
                  <TechnologyMobileButton onClick={() => setShowDownloadModal(true)}>Download Now</TechnologyMobileButton>
                  <AppLink onClick={() => setShowDesktopScreenshotModal(!showDesktopScreenshotModal)}>See Screenshots</AppLink>
                  <AppLink
                    onClick={() => setShowMoreDesktop(!showMoreDesktop)}
                  >
                    Show More{' '}
                    <FontAwesomeIcon
                      icon={desktopIcon}
                      aria-label={desktopIcon}
                    />
                  </AppLink>
                  {showMoreDesktop && (
                    <DetailsSection>
                      <h3 className='h5'>Best For</h3>
                      <p>
                        High-powered, high-volume traders who need a complete{' '}
                        tool-set of features coupled with blazing performance.
                      </p>
                      <h3 className='h5'>Features</h3>
                      <ul>
                        <li>Lightning-fast quotes and execution speeds</li>
                        <li>Advanced multi-account support and reporting</li>
                        <li>Intuitive dashboard layout and workflow</li>
                      </ul>
                      <h3 className='h5'>Requirements*</h3>
                      <ul>
                        <li>RAM: 2 GB minimum, 8 GB recommended</li>
                        <li>
                          Hard Disk Space: 200 MB for installation, 250 MB rec.
                        </li>
                        <li>Connection Speed: 1.5 Mbps min., 6 Mbps rec.</li>
                      </ul>
                    </DetailsSection>
                  )}
                </Col>
                <Col md={12} lg={4} className='mt-5'>
                  <img className='tech-image' src={laptop} alt='laptop' />
                  <h3 className='h5'>Browser</h3>
                  <p>
                    If you prefer to trade in your web browser, then we’ve got one
                    of the most robust web-based trading platforms in the
                    industry. You can access it from any computer (even the one
                    your IT department doesn’t allow you to install software on),
                    and you get all the same functionality as our downloadable
                    version!
                  </p>
                  <TechnologyMobileButton href={externalLinks.tastyworksBrowserApp} target='_blank' rel='noopener noreferrer'>Try It Now</TechnologyMobileButton>
                  <AppLink onClick={() => setShowBrowserScreenshotModal(!showBrowserScreenshotModal)}>See Screenshots</AppLink>
                  <AppLink
                    onClick={() => setShowMoreBrowser(!showMoreBrowser)}
                  >
                    Show More{' '}
                    <FontAwesomeIcon
                      icon={browserIcon}
                      aria-label={browserIcon}
                    />
                  </AppLink>
                  {showMoreBrowser && (
                    <DetailsSection>
                      <h3 className='h5'>Best For</h3>
                      <p>
                        Traders who like the flexibility of web-based software
                        with technology powerful enough to feel like a desktop
                        application.
                      </p>
                      <h3 className='h5'>Features</h3>
                      <ul>
                        <li>
                          Follow our curated experts’ trades on the Follow page
                        </li>
                        <li>Visualize your trades easily on the Trade Curve</li>
                        <li>View the history of your trade chain on Analyze</li>
                      </ul>
                      <h3 className='h5'>Requirements*</h3>
                      <ul>
                        <li>Screen: 1024x768 minimum, 1600x1200 recommended</li>
                        <li>
                          Browser: Chrome version 50+, MS Edge version 38+,
                          Firefox version 48+ or Safari version 8+
                        </li>
                      </ul>
                    </DetailsSection>
                  )}
                </Col>
                <Col md={12} lg={4} className='mt-5'>
                  <img className='tech-image' src={mobile} alt='Mobile' />
                  <h3 className='h5'>Mobile</h3>
                  <p>
                    We know you’re busy, but you still need to stay on top of your
                    trading. tastytrade mobile is discreet and fast enough to
                    trade while in meetings, on lunch dates, and anywhere else you
                    can get a signal.
                  </p>
                  <DownloadTasty href={externalLinks.iosDownload} target='_blank' rel='noopener noreferrer'>Get For IOS</DownloadTasty>
                  <br />
                  <DownloadTasty href={externalLinks.androidDownload} target='_blank' rel='noopener noreferrer'>Get For Android</DownloadTasty>
                  <AppLink
                    onClick={() => setShowMoreMobile(!showMoreMobile)}
                  >
                    Show More{' '}
                    <FontAwesomeIcon
                      icon={mobileIcon}
                      aria-label={mobileIcon}
                    />
                  </AppLink>
                  {showMoreMobile && (
                    <DetailsSection>
                      <h3 className='h5'>Best For</h3>
                      <p>
                        Users who need powerful trading technology at their
                        fingertips at all times. And those of us who check our
                        positions about once every 5 minutes or so.
                      </p>
                      <h3 className='h5'>Features</h3>
                      <ul>
                        <li>
                          Feature-rich order entry interface with visualization
                        </li>
                        <li>
                          Quick, easy methods for closing or rolling positions
                        </li>
                        <li>
                          All the best stuff from our full-sized apps, just
                          smaller
                        </li>
                      </ul>
                      <h3 className='h5'>Requirements*</h3>
                      <ul>
                        <li>Apple Phone: iPhone 6 or later</li>
                        <li>Android Phone: Nexus 6 comparable or better</li>
                      </ul>
                    </DetailsSection>
                  )}
                </Col>
              </Row>
            </Container>
            <hr />
            <Footnotes>
              <h3 className='h5'>*Requirements</h3>
              <SmallPrint>
                In general, the following operating system versions are supported.{' '}
                Other older versions may work, but are not officially supported.
              </SmallPrint>
              <SmallPrint>
                <p>Windows: Windows 10 minimum, Windows 11 recommended</p>
                <p>Mac OS X: version 11 minimum, 13 recommended</p>
                <p>Linux: Ubuntu 18.04 LTS minimum, 18+ recommended</p>
                <p>iOS/Apple Mobile : iOS 14 minimum</p>
                <p>Android : 11 minimum, 13 recommended</p>
              </SmallPrint>
              <h3 className='h5'>Disclaimer</h3>
              <SmallPrint>
                System response and access times may vary due to trading volume,{' '}
                market conditions, system performance, and other factors.
              </SmallPrint>
            </Footnotes>
          </PlatformSection>
          <ReadyToTrade />
          <TeamSection
            dark
            tagline='from the development team!'
            imageSrc={techTeam}
            imageAlt='Meet the Tech Team'
          />
        </TechnologyWrapper>
        <DownloadModal toggle={() => setShowDownloadModal(prev => !prev)} isOpen={showDownloadModal} />
        <DesktopScreenshotModal toggle={() => setShowDesktopScreenshotModal(prev => !prev)} isOpen={showDesktopScreenshotModal} />
        <BrowserScreenshotModal toggle={() => setShowBrowserScreenshotModal(prev => !prev)} isOpen={showBrowserScreenshotModal} />
        <MobileScreenshotModal toggle={() => setShowMobileScreenshotModal(prev => !prev)} isOpen={showMobileScreenshotModal} />
      </main>
    </Layout>
  );
}

export default withLocation(TechnologyPage);
