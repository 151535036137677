import React from 'react'
import { Location } from '@reach/router'
import queryString from 'query-string'

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

function withLocation (ComponentToWrap) {
  function WithLocation (props) {
    return (
      <Location>
        {({ location, navigate }) => (
          <ComponentToWrap
            {...props}
            location={location}
            navigate={navigate}
            search={location.search ? queryString.parse(location.search) : {}}
          />
        )}
      </Location>
    );
  }

  WithLocation.displayName = `WithLocation(${getDisplayName(ComponentToWrap)})`;

  return WithLocation;
}

export default withLocation;
