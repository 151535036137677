import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Section from 'components/Section';
import { ifProp, prop } from 'styled-tools';
import grungeDark from 'images/grunge-background-dark2.png';

const propTypes = {

};

const defaultProps = {

};

const TeamSectionWrapper = styled(Section)`
  &&& {
    text-align: center;

    ${ifProp('dark',
      css`
        padding: 0;
      `
    )}

    img {
      width: 100%;
    }
  }
`;

const TeamHeader = styled.h2`
  text-align: center;
  line-height: 0.8;
  margin-bottom: 3rem;
  padding: 3.125rem 0 1rem;
  margin: 0;
  color: ${prop('theme.colors.white')};

  span {
    color: ${ifProp('dark', prop('theme.colors.white'))};
    font-family: ${prop('theme.fonts.condensed')};
    font-weight: bold;
    font-size: 3.125rem;
    line-height: 1;
    text-transform: uppercase;
  }

  small {
    font-family: ${prop('theme.fonts.default')};
    font-size: 1.125rem;
    font-weight: bold;
    line-height: 1;
  }
`;

function TeamSection({ tagline, imageSrc, imageAlt, dark }) {
  return (
    <TeamSectionWrapper dark={dark}>
      <TeamHeader>
        <span>Hello,</span>
        <br />
        <small>{tagline}</small>
      </TeamHeader>
      <img src={imageSrc} alt={imageAlt} />
    </TeamSectionWrapper>
  );
}

TeamSection.propTypes = propTypes;
TeamSection.defaultProps = defaultProps;

export default TeamSection;
