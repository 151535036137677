import React, { useState } from 'react';
import Fslightbox from 'fslightbox-react';
import twTradeTable from 'images/tw-trade-table.jpg'
import twTradeCurve from 'images/tw-trade-curve.jpg'
import twPortfolioTab from 'images/tw-portfolio-tab.jpg';
import twActivityTab from 'images/tw-activity-tab.jpg';
import twActivityTabHistory from 'images/tw-activity-tab-history.jpg';
import twFollowTab from 'images/tw-follow-tab.jpg';
import twGridTab from 'images/tw-grid-tab.jpg';
import twLoginPage from 'images/tw-login-page.jpg';

function BrowserScreenshotModal ({ isOpen }) {
  return (
    <>
      <Fslightbox
        toggler={ isOpen }
        sources={[
          twTradeTable,
          twTradeCurve,
          twPortfolioTab,
          twActivityTab,
          twActivityTabHistory,
          twFollowTab,
          twGridTab,
          twLoginPage
        ]}
      />
    </>
  )
}

export default BrowserScreenshotModal;