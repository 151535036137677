import React, { useState } from 'react'; 
import FsLightbox from 'fslightbox-react';
import ttDesktopOptionsGraph from 'images/tt-desktop.png';
import ttDesktopOptionsChart from 'images/tt-desktop-options.png';
import ttDesktopStockScreen from 'images/tt-desktop-stock-screen.png';
import ttDesktopFuturesScreen from 'images/tt-desktop-futures-screen.png';
import ttDesktopFuturesGrid from 'images/tt-desktop-futures-grid.png';
import ttDesktopStock from 'images/tt-desktop-stock.png';

function DesktopScreenshotModal ({ isOpen }) { 

  return ( 
    <> 
      <FsLightbox 
        toggler={ isOpen  } 
        sources={[
          ttDesktopOptionsGraph,
          ttDesktopOptionsChart,
          ttDesktopStockScreen,
          ttDesktopFuturesScreen,
          ttDesktopFuturesGrid,
          ttDesktopStock
        ]}
      /> 
    </> 
  ); 
}









export default DesktopScreenshotModal;