import React, { useState } from 'react';
import Fslightbox from 'fslightbox-react';
import twMobileFollow from 'images/tw-mobile-follow.jpg';
import twMobileTrade from 'images/tw-mobile-trade.jpg';
import twMobileTable from 'images/tw-mobile-table.jpg';
import twMobileCheck from 'images/tw-mobile-check.jpg';
import twMobileView from 'images/tw-mobile-view.jpg';
import twMobileVisualize from 'images/tw-mobile-visualize.jpg';

function MobileScreenshotModal ({ isOpen }) {
  return (
    <>
      <Fslightbox
        toggler={ isOpen }
        sources={[
          twMobileFollow,
          twMobileTrade,
          twMobileTable,
          twMobileCheck,
          twMobileView,
          twMobileVisualize
        ]}
      />
    </>
  )
}


export default MobileScreenshotModal;