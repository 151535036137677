import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  src: PropTypes.string.isRequired,
  hoverSrc: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  preload: PropTypes.bool,
  className: PropTypes.string,
};

const defaultProps = {
  preload: true,
  className: undefined
};

function ImageHoverSwap({ src, hoverSrc, alt, preload, className }) {
  const [currentSrc, setCurrentSrc] = useState(src);

  // Preload hover image if preload prop is true.
  useEffect(() => {
    if (preload) {
      /* global Image */
      const preloaded = new Image();
      // Setting src here triggers browser to load the image.
      preloaded.src = hoverSrc;
    }
  }, []);

  return (
    <img
      src={currentSrc}
      onMouseOver={() => setCurrentSrc(hoverSrc)}
      onFocus={() => setCurrentSrc(hoverSrc)}
      onMouseOut={() => setCurrentSrc(src)}
      onBlur={() => setCurrentSrc(src)}
      alt={alt}
      className={className}
    />
  );
}

ImageHoverSwap.propTypes = propTypes;
ImageHoverSwap.defaultProps = defaultProps;

export default ImageHoverSwap;
